<template>
    <div v-if="data.records !== undefined" class="m-3">
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <!-- title, breadcrumb -->
            <b-row>
                <!--  title  -->
                <b-col class="" cols="auto">
                    <h4>{{$tc(localeKey, 1)}}</h4>
                </b-col>
                <!-- breadcrumb, showing -->
                <b-col class="mr-auto ml-0 px-0" cols="auto">
                    <b-button-group size="sm">
                        <!-- breadcrumb -->
                        <b-button disabled variant="transparent"><b>
                            {{getBreadcrumb}}
                        </b>
                        </b-button>
                        <!-- showing -->
                        <b-button disabled variant="transparent"><b>
                            {{__getPaginationVisibleCount(data.records.length, $root.mx_pagination.perPage, currentPage)}}
                        </b>
                        </b-button>
                    </b-button-group>
                </b-col>
                <!-- recordsVisibleCount -->
                <b-col class="mr-auto ml-0 px-0" cols="auto">
                    <b-button-group size="sm">
                        <b-button disabled variant="transparent"><b>

                        </b>
                        </b-button>
                    </b-button-group>
                </b-col>
            </b-row>
            <!-- no hits -->
            <div v-if="data.records.length === 0">
                {{$t('form.no_hits')}}
            </div>
            <!-- suppliers -->
            <b-card-group deck>
<!--            <b-row>-->
                <supplier
                    v-for="record in __getPaginationArrayItems(data.records, $root.mx_pagination.perPage, currentPage)"
                    :data="data.data"
                    :record="record"
                    :uri="uri"
                ></supplier>
<!--            </b-row>-->
            </b-card-group>
            <!-- pagination -->
            <b-pagination
                v-model="currentPage"
                :per-page="$root.mx_pagination.perPage"
                :total-rows="data.records.length"
                limit="20"
                size="sm"
            ></b-pagination>
        </b-overlay>
    </div>
</template>>

<script>
import Supplier from "@/components/_public/supplier/Supplier";

export default {
    name: "Suppliers",
    components: {Supplier},
    props: {},
    data() {
        return {
            localeKey: 'public.supplier.main',
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            uri: {
                main: `api/v1/_public/suppliers`,
                uri1: `api/v1/_public/supplier`,
            },
            data: [],
            currentPage: 1,
        }
    },
    mounted() {
        this.$root.mx_pagination.visible = true;
        this.baseSelect();
    },
    destroyed() {
        this.$root.mx_pagination.visible = false;
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- api calls -->
        baseSelect: function () {
            this.showOverlay(true);
            this.axios.get(this.uri.uri1 + `/${this.$route.params.group_id}`)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {
        getBreadcrumb() {
            if (this.__isNull(this.data) || !this.data.records.length > 0) {
                return null;
            }
            let group_id = this.$route.params.group_id;
            // <-- all products -->
            if (group_id === 'null') {
                return '(' + this.$t('public.supplier.all') + ')';
            }
            // <--  -->
            group_id = parseInt(group_id);
            let groups = this.data.data.groups;
            // <-- group -->
            let group = groups.find((n) => {
                if (n.id === group_id) {
                    return n;
                }
            });
            // <-- name -->
            let group_name = group.meta.name;
            let localeTemp = this.__getJsonLocaleValue(group.locale, 'name');
            group_name = localeTemp === null ? group_name : localeTemp;
            // <--  -->
            return '(' + group_name + ')';
        },
    },
    filters: {},
    watch: {
        '$route.params.group_id': {
            handler: function (value, oldValue) {
                this.baseSelect();
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
