<template>
<!--    <div class="col-sm-12 col-lg-6 col-xl-4">-->
    <div>
        <b-card
            class="bg-info mb-3 farm-card shadow"
            no-body
        >
<!--            style="width: 100%; max-width: 450px"-->
            <b-row no-gutters>
                <!-- picture -->
                <b-col class="farm-card-image" cols="6">
                    <b-img :src="getPictureSrc" class="rounded-0"/>
                </b-col>
                <!-- content -->
                <b-col cols="6" class="farm-card-text">
                    <!-- organisation type -->
                    <b-card-body
                        :sub-title="getOrgGroup"
                    >
                        <b-row no-gutters>
                            <b-col cols="12" class="farm-card-body">
                                <!-- organisation name -->
                                <b-card-title>{{getOrgName}}</b-card-title>
                                <!-- city -->
                                <b-card-sub-title>{{getCity}}{{getRegion}}</b-card-sub-title>
                            </b-col>
                            <b-col cols="12">

                                <b-button
                                    class="mb-0 mt-1"
                                    style="width: 100%;"
                                    variant="primary"
                                    @click="more()"
                                >
                                    {{$t('form.more')}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-col>
            </b-row>
        </b-card>
        <!-- modal -->
        <b-modal :id="'modal-supplier_' + record.id"
                 :ok-title="$t('form.close')"
                 footer-bg-variant="secondary"
                 header-bg-variant="secondary"
                 header-text-variant="white"
                 ok-only
                 ok-variant="primary"
                 scrollable
                 size="xl"
                 :title="getOrgName"
                 title-class="font-weight-bold"
                 variant="secondary"
        >
            <ModalSupplier
                :uri="uri"
                :id="record.id"
            ></ModalSupplier>
        </b-modal>
        <!--        </b-col>-->
    </div>
</template>

<script>
import ModalSupplier from "@/components/_public/supplier/ModalSupplier";

export default {
    name: "Supplier",
    components: {ModalSupplier},
    props: {
        uri: {
            type: Object,
            default: {}
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        more() {
            this.$bvModal.show('modal-supplier_' + this.record.id);
        },
    },
    computed: {
        getPictureSrc: function () {
            if (this.__isNull(this.record.pictures_order) || this.record.pictures_order.length === 0 || this.record.pictures_order[0] === undefined) {
                return this.__getDefaultImage;
            }
            // <-- key -->
            let key = null;
            this.record.pictures_order.forEach((value, index) => {
                if (key === null && value.publish) {
                    key = value.key;
                }
            });

            if (key === null) {
                return null;
            }
            // <-- src -->
            let src = null;
            this.record.pictures.find((picture) => {
                if (picture.key === key) {
                    src = picture.image.src;
                }
            });
            // <--  -->
            return this.__getImageSrc(src);
        },
        // getOrgType() {
        //     if (this.__isNull(this.record.org_type)) {
        //         return null;
        //     }
        //     // <--  -->
        //     let value = this.record.org_type.meta.name_full;
        //     return value;
        // },
        getOrgGroup() {
            if (this.__isNull(this.record.org_type) || this.__isNull(this.data.groups)) {
                return null;
            }
            // <--  -->
            let group_id = this.record.org_type.org_group_id;
            let group = null;
            // <--  -->
            this.data.groups.find((n) => {
                if (n.id === group_id) {
                    group = n.meta.name;
                    // <-- locale -->
                    if (n.locale[this.$i18n.locale] !== undefined) {
                        if (!this.__isNull(n.locale[this.$i18n.locale].name)) {
                            group = n.locale[this.$i18n.locale].name;
                        }
                    }
                }
            });
            // <--  -->
            return group;
        },
        getOrgName() {
            if (this.__isNull(this.record.name)) {
                return null;
            }
            let value = this.__isNull(this.record.name.name) ? this.record.name.full_name : this.record.name.name;
            return value;
        },
        getCity() {
            if (this.__isNull(this.record.post_office)) {
                return null;
            }
            // <--  -->
            let value = this.record.post_office.city;
            return value;
        },
        getRegion() {
            if (this.__isNull(this.record.region)) {
                return null;
            }
            // <--  -->
            let value = this.record.region.meta.name;
            value = this.__isNull(this.getCity) ? value : ', ' + value
            return value;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.card-body {
    padding-bottom: 0.7rem!important;
}
</style>
